// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-chpdc-faq-js": () => import("./../../../src/pages/CHPDC/faq.js" /* webpackChunkName: "component---src-pages-chpdc-faq-js" */),
  "component---src-pages-chpdc-index-js": () => import("./../../../src/pages/CHPDC/index.js" /* webpackChunkName: "component---src-pages-chpdc-index-js" */),
  "component---src-pages-chpdc-letter-js": () => import("./../../../src/pages/CHPDC/letter.js" /* webpackChunkName: "component---src-pages-chpdc-letter-js" */),
  "component---src-pages-chpdc-monitoring-js": () => import("./../../../src/pages/CHPDC/monitoring.js" /* webpackChunkName: "component---src-pages-chpdc-monitoring-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-thp-faq-js": () => import("./../../../src/pages/THP/faq.js" /* webpackChunkName: "component---src-pages-thp-faq-js" */),
  "component---src-pages-thp-index-js": () => import("./../../../src/pages/THP/index.js" /* webpackChunkName: "component---src-pages-thp-index-js" */),
  "component---src-pages-thp-letter-js": () => import("./../../../src/pages/THP/letter.js" /* webpackChunkName: "component---src-pages-thp-letter-js" */),
  "component---src-pages-thp-monitoring-js": () => import("./../../../src/pages/THP/monitoring.js" /* webpackChunkName: "component---src-pages-thp-monitoring-js" */)
}

